<template>
  <b-overlay :show="isSubmitting" rounded="sm">
    <validation-observer ref="simpleRules">
      <b-container class="col">
        <b-card>
          <b-card-text class="h1 mb-1 font-large-1 text-primary">
            Pickup Information
          </b-card-text>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="col-12 col-lg-6 w-100">
                  <b-form-group label="FIRST NAME">
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form.first_name"
                        type="text"
                        placeholder="First name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                    class="w-100"
                  >
                    <b-form-group label="LAST NAME">
                      <b-form-input
                        v-model="form.last_name"
                        type="text"
                        placeholder="Last name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="w-100">
                  <b-form-group label="CONTACT">
                    <validation-provider
                      #default="{ errors }"
                      name="contact"
                      rules="required|digits:11"
                      class="w-100"
                    >
                      <b-form-input
                        name="contact"
                        v-model="form.contact"
                        type="text"
                        placeholder="Phone number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="w-100">
                  <b-form-group label="PICKUP ADDRESS">
                    <validation-provider
                      #default="{ errors }"
                      name="pickup address"
                      rules="required"
                      class="w-100"
                    >
                      <b-form-input
                        v-model="form.address"
                        name="pickup address"
                        type="text"
                        placeholder="Enter pickup location"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="w-100">
                  <b-form-group label="REMARKS">
                    <validation-provider
                      #default="{ errors }"
                      name="remarks"
                      rules="required"
                      class="w-100"
                    >
                      <b-form-input
                        name="remarks"
                        v-model="form.remarks"
                        type="text"
                        placeholder="What would you want driver to note"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="w-100">
                  <b-form-group label="PRODUCT CODE">
                    <b-form-input
                      name="product code"
                      type="text"
                      placeholder="Optional"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="col-12 col-lg-6 w-100">
                  <b-form-group label="PICKUP DATE">
                    <validation-provider
                      #default="{ errors }"
                      name="pickup date"
                      rules="required"
                    >
                      <flat-pickr
                        class="form-control"
                        v-model="form.pickup_date"
                        placeholder="Choose Date"
                        readonly="readonly"
                        :config="{
                          minDate: 'today',
                        }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="pickup time"
                    rules="required"
                    class="w-100"
                  >
                    <b-form-group label="PICKUP TIME">
                      <flat-pickr
                        class="form-control"
                        v-model="form.pickup_time"
                        placeholder="Choose Time"
                        readonly="readonly"
                        :config="{
                          enableTime: true,
                          noCalendar: true,
                          minTime: '16:00',
                          maxTime: '22:30',
                        }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>

        <!-- DELIVERY INFORMATION -->
        <b-card
          v-for="(row, index) in rows"
          :key="index"
          :itemdata="form.deliveries"
          :row="row"
        >
          <div class="d-flex justify-content-between">
            <b-card-text class="h1 mb-1 font-large-1 text-primary">
              Delivery Information
            </b-card-text>
            <div v-if="index != 0">
              <b-button
                variant="primary"
                size="sm"
                v-on:click="removeRow(index)"
                >X</b-button
              >
            </div>
          </div>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="col-12 col-lg-6 w-100">
                  <b-form-group label="FIRST NAME">
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="row.first_name"
                        type="text"
                        placeholder="First name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                    class="w-100"
                  >
                    <b-form-group label="LAST NAME">
                      <b-form-input
                        v-model="row.last_name"
                        type="text"
                        placeholder="Last name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="w-100">
                  <b-form-group label="CONTACT">
                    <validation-provider
                      #default="{ errors }"
                      name="contact"
                      rules="required|digits:11"
                      class="w-100"
                    >
                      <b-form-input
                        name="contact"
                        v-model="row.contact"
                        type="text"
                        placeholder="Phone number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="col-12 col-lg-6 w-100">
                  <b-form-group label="DELIVERY ADDRESS">
                    <validation-provider
                      #default="{ errors }"
                      name="delivery address"
                      rules="required"
                    >
                      <b-form-input
                        v-model="row.address"
                        type="text"
                        placeholder="Delivery Address"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="State"
                    rules="required"
                    class="w-100"
                  >
                    <b-form-group label="DELIVERY STATE">
                      <select
                        class="form-control form-select rounded mr-2"
                        v-model="row.state"
                      >
                        <option value="" disabled selected>State</option>
                        <option v-for="(state, index) in state" :key="index">
                          {{ state.state_name }}
                        </option>
                      </select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="w-100">
                  <b-form-group label="REMARKS">
                    <validation-provider
                      #default="{ errors }"
                      name="remarks"
                      rules="required"
                      class="w-100"
                    >
                      <b-form-input
                        name="remarks"
                        v-model="row.remarks"
                        type="text"
                        placeholder="What would you want driver to note"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="col-16">
              <b-row>
                <b-col class="col-12 col-lg-6 w-100">
                  <b-form-group label="QUANTITY">
                    <validation-provider
                      #default="{ errors }"
                      name="quantity"
                      rules="required"
                    >
                      <b-form-input
                        v-model="row.quantity"
                        type="text"
                        placeholder="Quantity"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="weight"
                    rules="required"
                    class="w-100"
                  >
                    <b-form-group label="WEIGHT">
                      <b-form-input
                        v-model="row.weight"
                        type="text"
                        placeholder="Weight"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <div class="d-flex justify-content-end">
          <b-button
            @click="addRow"
            variant="gradient-primary"
            class="box-shadow-1 d-flex justify-content-end"
            >Add Delivery</b-button
          >
        </div>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <b-button
            @click="onSubmit"
            variant="gradient-primary"
            class="box-shadow-1 px-5 w-25"
            >Submit</b-button
          >
        </div>
      </b-container>
    </validation-observer>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
        <h3 class="mt-3">Creating your order. Please wait...</h3>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      isDeliverToday: true,
      form: {
        delivery_time: "",
        delivery_date: "",
        order_type: "DHL",
        branch_destination: "",
        pickup_id: "",
        deliveries: [],
      },
      state: [],
      rows: [
        {
          first_name: "",
          last_name: "",
          address: "",
          state: "",
          remarks: "",
          quantity: "",
          weight: "",
        },
      ],
    };
  },
  created() {
    if (this.$route.params.data) {
      this.form = this.$route.params.data;
      this.rows = this.form.deliveries;
    }
  },
  mounted() {
    this.$http.get(`state`).then((response) => {
      this.state = response.data.data;
    });
    this.form.delivery_date = new Date().toISOString().split("T")[0];
    this.form.delivery_time = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  },
  methods: {
    addRow() {
      this.rows.push({
        first_name: "",
        last_name: "",
        address: "",
        state: "",
        remarks: "",
        quantity: "",
        weight: "",
      });
    },
    removeRow(index) {
      this.rows.splice(index, 1);
    },
    onSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.isSubmitting = true;
          this.form.deliveries = this.rows;
          this.$router.push({
            name: "DHL-paymentmethod",
            params: { data: this.form },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/style.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.text {
  color: rgb(216, 54, 54);
  font-weight: bold;
}

.custom-file-label {
  font-size: 14px;
}
</style>
